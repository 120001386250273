import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import { Link } from "gatsby"

import ImageRenderer from "../components/imageRenderer"

const TagTemplate = ({ data }) => {
  const tag = data.tags
  const posts = data.allPosts.edges

  return (
    <Layout style={{ maxWidth: "1100px" }}>
      <SEO
        title={`${tag.name} | Iqara Islam`}
        description={tag.description}
        isCategory={true}
      />

      <div className="main main-category" style={{ marginBottom: 30 }}>
        <div className="category-breadcrumb">
          <Link to="/">
            <h2>Home</h2>
          </Link>{" "}
          / <h2 dangerouslySetInnerHTML={{ __html: tag.name }}></h2>
        </div>

        <div className="posts">
          {posts.map(post => (
            <Link to={post.node.slug} className="post" key={post.node.slug}>
              <ImageRenderer imageData={post.node.imageUrl} />
              <h2 dangerouslySetInnerHTML={{ __html: post.node.name }}></h2>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default TagTemplate

export const query = graphql`
  query($id: String!, $slug: String!) {
    tags(id: { eq: $id }) {
      name
      slug
    }

    allPosts(filter: { tags: { elemMatch: { slug: { eq: $slug } } } }) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }
  }
`
